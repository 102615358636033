import { DisplayRule } from './display-rule';

// Matches server obj ApiResult
export interface ItemResponse {
    data: any;
    displayRules: DisplayRule[];
    errors: string[];
    fieldErrors: Map<string, string[]>;
    messages: string[];
    notes: any;
    noteTypes: any;
    noteSubjects: any;
  }

export function isItemResponse(response: ItemResponse): response is ItemResponse {
    return response && ((response as ItemResponse).data !== undefined || (response as ItemResponse).displayRules !== undefined) &&
      (response as ItemResponse).errors !== undefined &&
        // uncomment or delete after confirmation from swetha
      // (response as ItemResponse).fieldErrors !== undefined &&
      (response as ItemResponse).messages !== undefined;
  }
