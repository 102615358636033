import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { ErrorHandlerComponent } from '../common/error-handler/error-handler.component';
import { SpinnerComponent } from '../common/spinner/spinner.component';
import { LabelComponent } from '../fields/controls/label/label.component';
import { TextFieldComponent } from '../fields/controls/text-field/text-field.component';
import { TextAreaComponent } from '../fields/controls/text-area/text-area.component';
import { DropDownComponent } from '../fields/controls/drop-down/drop-down.component';
import { RadioComponent } from '../fields/controls/radio/radio.component';
import { CheckboxComponent } from '../fields/controls/checkbox/checkbox.component';
import { ButtonComponent } from '../fields/controls/button/button.component';
import { DynamicPipe } from '../fields/pipes/dynamic.pipe';
import { RestrictCharactersDirective } from '../fields/directives/restrict-characters.directive';
import { AutoFocusDirective } from '../fields/directives/auto-focus-directive';
import { AppFieldValidatorsDirective } from '../fields/directives/app-field-validators.directive';
import { BulkCheckPaymentDialogComponent } from '../common/bulk-check-payment-dialog/bulk-check-payment-dialog.component';
import { GooglePlacesDirective } from '../fields/directives/google-places.directive';
import { DatePickerComponent } from '../fields/controls/date-picker/date-picker.component';
import { SelectionListComponent } from '../fields/controls/selection-list/selection-list.component';
import { MaskedInputComponent } from '../fields/controls/masked-input/masked-input.component';
import { ControlErrorComponent } from '../fields/controls/control-error/control-error.component';
import { MaskInputPipe } from '../fields/pipes/mask-input.pipe';
import { MaskInputDecimalPipe } from '../fields/pipes/mask-input-number.pipe';
import { AutoCompleteComponent } from '../fields/controls/auto-complete/auto-complete.component';
import { DrangAndDropDirective } from '../fields/directives/drag-and-drop.directive';
import { TextReadonlyFieldComponent } from '../fields/controls/text-readonly-field/text-readonly-field.component';
import { UniversalTableComponent } from '../common/universal-table/universal-table.component';
import { TimePickerComponent } from '../fields/controls/time-picker/time-picker.component';
import { MasterAddressBookSearchComponent } from '../admin/master-address-book/master-address-book-search/master-address-book-search.component';
import { PhoneControlGroupComponent } from '../common/address-book-contact/contact-form/phone-control-group/phone-control-group.component';
import { ContactFormComponent } from '../common/address-book-contact/contact-form/contact-form.component';
import { inputFilterDirective } from '../fields/controls/masked-input-v2/input-filter.directive';
import { MaskedInputV2Component } from '../fields/controls/masked-input-v2/masked-input-v2.component';
import { ContactGridComponent } from '../common/address-book-contact/contact-grid/contact-grid.component';
import { LastChancePromptComponent } from '../fields/controls/button/last-chance-prompt/last-chance-prompt.component';
import { ContactModalComponent } from '../common/address-book-contact/contact-modal/contact-modal.component';
import { AddMasterAddressBookContactComponent } from '../common/address-book-contact/add-master-address-book-contact/add-master-address-book-contact.component';
import { ContactFormControlsComponent } from '../common/address-book-contact/contact-form/contact-form-controls/contact-form-controls.component';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {CustomDatePickerHeaderComponent} from '../fields/controls/date-picker/custom-date-picker-header/custom-date-picker-header.component';
import {AddressDisplayComponent} from '../common/contact-information/address-display/address-display.component';
import {PhoneDisplayComponent} from '../common/contact-information/phone-display/phone-display.component';
import {SafeUrlTransformPipe} from '../common/safe-url-transform.pipe';
import { InternationalAddressFormControlsComponent } from '../common/address-book-contact/contact-form/contact-form-controls/international-address-form-controls/international-address-form-controls.component';

@NgModule({
  declarations: [
    AutoCompleteComponent,
    BulkCheckPaymentDialogComponent,
    ErrorHandlerComponent,
    SpinnerComponent,
    LabelComponent,
    TextFieldComponent,
    TextAreaComponent,
    DropDownComponent,
    DatePickerComponent,
    CustomDatePickerHeaderComponent,
    RadioComponent,
    CheckboxComponent,
    ButtonComponent,
    LastChancePromptComponent,
    DynamicPipe,
    RestrictCharactersDirective,
    AutoFocusDirective,
    AppFieldValidatorsDirective,
    GooglePlacesDirective,
    DrangAndDropDirective,
    ControlErrorComponent,
    SelectionListComponent,
    MaskedInputComponent,
    MaskedInputV2Component,
    TextReadonlyFieldComponent,
    UniversalTableComponent,
    PhoneControlGroupComponent,
    ContactFormComponent,
    ContactGridComponent,
    inputFilterDirective,
    TimePickerComponent,
    MasterAddressBookSearchComponent,
    ContactModalComponent,
    AddMasterAddressBookContactComponent,
    ContactFormControlsComponent,
    InternationalAddressFormControlsComponent,
    AddressDisplayComponent,
    PhoneDisplayComponent,
    SafeUrlTransformPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    DragDropModule
  ],
  exports: [
    AutoCompleteComponent,
    BulkCheckPaymentDialogComponent,
    CommonModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    ErrorHandlerComponent,
    SpinnerComponent,
    LabelComponent,
    TextFieldComponent,
    TextAreaComponent,
    DropDownComponent,
    DatePickerComponent,
    CustomDatePickerHeaderComponent,
    RadioComponent,
    CheckboxComponent,
    ButtonComponent,
    LastChancePromptComponent,
    DynamicPipe,
    RestrictCharactersDirective,
    AutoFocusDirective,
    AppFieldValidatorsDirective,
    GooglePlacesDirective,
    DrangAndDropDirective,
    SelectionListComponent,
    MaskedInputComponent,
    MaskedInputV2Component,
    TextReadonlyFieldComponent,
    UniversalTableComponent,
    MasterAddressBookSearchComponent,
    TimePickerComponent,
    PhoneControlGroupComponent,
    ContactFormComponent,
    ContactGridComponent,
    inputFilterDirective,
    ContactModalComponent,
    AddMasterAddressBookContactComponent,
    ContactFormControlsComponent,
    InternationalAddressFormControlsComponent,
    AddressDisplayComponent,
    PhoneDisplayComponent,
    SafeUrlTransformPipe
  ],
  providers: [MaskInputPipe, MaskInputDecimalPipe],
  entryComponents: [
    BulkCheckPaymentDialogComponent,
    ErrorHandlerComponent,
    SpinnerComponent,
  ],
})
export class SharedModule {}
