import {Injectable} from '@angular/core';
import {ProductLineService} from '../main-menu/product-line.service';
import {ApplicationConfig} from '@nextgen/task-notes-library';
import {ENTITY_TYPE, MANAGEMENT_TYPE} from '@nextgen/task-notes-library/shared/common.model';

@Injectable({ providedIn: 'root' })
export class TasksService implements ApplicationConfig{
    currentProductLine: string;

    constructor(private productLineService: ProductLineService) {
        this.productLineService.currentProductLine.subscribe(productLine => {
                this.currentProductLine = productLine;
            }
        );
    }

    public get appConfigFeatures() {
        return [
            'TASK_SUBJECT',
            'TASK_EDIT_MESSAGE',
            'HIDE_MESSAGE_TYPE',
            'HIDE_NOTE_VIEW_POLICY_NO',
            'HIDE_MESSAGE_TYPE'
        ];
    }

    public get managementType(): MANAGEMENT_TYPE {
        return 'CLAIMS_MGMT';
    }

    public get draggablePanels() {
        return false;
    } 

    public get entityType(): ENTITY_TYPE {
        return 'CLM';
    }

    public get productLine() {
        return this.currentProductLine;
    }
}
