import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UniversalTableDataConverterService {

  ruleServiceNodeToArray(dataNode: any): any[] {
    const dataArray = [];
    if (dataNode?.tokenNames) {
      dataNode[`tokenNames`].forEach(token => {
        const tokenData = dataNode[token];
        const columns = Object.keys(tokenData);
        const dataRow: any = {};
        columns.forEach(col => {
          dataRow[col] = this.getDataRow(tokenData, col);
        });
        dataRow.tokenName = token;
        dataArray.push(dataRow);
      });
    }
    return dataArray;
  }

  private getDataRow(tokenData, col) {
    if (tokenData[col]) {
      return tokenData[col];
    }
  }
}
