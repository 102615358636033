import {ElementRef, Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {DataType, LastChancePromptComponent} from './last-chance-prompt.component';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LastChancePromptServiceService {

  dialogRef: MatDialogRef<LastChancePromptComponent>;
  dialogConfig: MatDialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog) {
  }

  public open(toolTipPosition: any, dataType: DataType, customMessage: string, closeOnly: boolean, centerWarningPromptMessage: boolean) {
    this.dialogConfig.data = {
      toolTipPosition,
      dataType,
      customMessage,
      closeOnly,
      centerWarningPromptMessage
    };
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.closeOnNavigation = false;
    this.dialogConfig.minWidth = '400px';

    this.dialogRef = this.dialog.open(LastChancePromptComponent, this.dialogConfig);
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed()
      .pipe(
        take(1),
        map(res => {
            return res;
          }
        ));
  }
}
