<div class="movable"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
    <div class="d-flex justify-content-between" *ngIf="title?.length > 0">
        <h5 class="table-title">{{title}}</h5>
    </div>
    <mat-divider class="mb-3" *ngIf="title?.length > 0"></mat-divider>
    <mat-dialog-content *ngIf="message?.length > 0">
        {{message}}
    </mat-dialog-content>
    <mat-divider class="mb-3" *ngIf="message?.length > 0"></mat-divider>

    <mat-dialog-actions align="end">
        <app-button
                id="warningDialogYes"
                name="warningDialogYes"
                type="button"
                buttonName="Yes"
                (onClick)="yes()"
                buttonStyle="next"
                classes="mr-2">
        </app-button>
        <app-button
                id="warningDialogNo"
                name="warningDialogNo"
                type="button"
                buttonName="No"
                (onClick)="no()"
                buttonStyle="cancel">
        </app-button>
    </mat-dialog-actions>
</div>
