import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from "rxjs";
import {AdjusterManagementTab} from "./adjuster-management-tab";

@Injectable()
export class AdjusterManagementSaveGuard implements CanDeactivate<AdjusterManagementTab> {
    // Route guard to make sure that the form is saved before changing the tabs (Right now its specific to adjuster management)
     canDeactivate(component: AdjusterManagementTab,
                   currentRoute: ActivatedRouteSnapshot,
                   currentState: RouterStateSnapshot,
                   nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
         console.log(nextState);
         return nextState.url.indexOf('adjuster-management') === -1 ? true : component.save();
    }
}
