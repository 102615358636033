<div class="movable"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
<mat-dialog-content [@grow]>
  <div class="row">
    <img class="icon exclamation mt-1" alt="exclamation" src="../../../../../assets/images/ghost.gif">
    <div class="col">
      <div class="mb-1">
        <div *ngIf="dataType === 'formData' && !customMessage">
          There are <span class="font-weight-bold">unsaved changes</span> on this form.
          <br>
          Are you sure you want to proceed?
        </div>
        <div *ngIf="dataType === 'tableData' && !customMessage">
          Removing this entry is <span class="font-weight-bold">permanent</span>.
          <br>
          Are you sure you want to proceed?
        </div>
        <div *ngIf="customMessage">
          {{ customMessage }}
        </div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="closeOnly !== true">
        <button
                class="btn btn-small btn-success"
                type="button"
                (click)="onYes()">
          Yes
        </button>

        <button
          class="btn btn-small btn-danger ml-2"
          type="button"
          (click)="onNo()">
          No
        </button>
        &nbsp;
      </div>
      <div class="d-flex justify-content-end" *ngIf="closeOnly === true">
        <button
                class="btn btn-small btn-light mr-1"
                type="button"
                (click)="onNo()">
          Close
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
</div>
