import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { WarningDialogComponent } from './warning-dialog.component';
import { map, take } from 'rxjs/operators';

@Injectable()
export class WarningDialogService {

  dialogRef: MatDialogRef<WarningDialogComponent>;
  dialogConfig: MatDialogConfig = new MatDialogConfig();

  constructor(private dialog: MatDialog) {
  }

  public open(options) {
    this.dialogConfig.data = {
      title: options.title,
      message: options.message
    };
    this.setWarningDialogConfig();
    this.dialogRef = this.dialog.open(WarningDialogComponent, this.dialogConfig);
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed()
        .pipe(
            take(1),
            map(res => {
                  return res;
                }
            ));
  }

  private setWarningDialogConfig() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.closeOnNavigation = false;
    this.dialogConfig.width = '500px';
  }
}
