import { Injectable } from '@angular/core';
import { LocationStrategy } from "@angular/common";
import { Router, Event, NavigationEnd } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class BrowserBackService {

  configData: any;

  constructor(
    private locationStrategy: LocationStrategy,
    private router: Router
  ) {}

  browserHistory() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        history.pushState(null, null, window.location.href);
        this.locationStrategy.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
      }
    });
  }
}
