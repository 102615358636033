import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemResponse } from '../model/item-response';
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {
  private data$ = new BehaviorSubject<ItemResponse>(null);
  private dataStore: { data: ItemResponse } = { data: null };
  readonly data = this.data$.asObservable();
  count = 0;

  constructor() { }

  setData(inputData: ItemResponse) {
    // console.log('dataStore setData', inputData);
    if (!inputData) {
      return;
    }
    if (this.isDataStoreEmpty()) {
      this.updateDataStore(inputData);
    } else {

      const oldData = this.dataStore.data || {};
      if (oldData) {
        this.dataStore.data = this.mergeDeep(oldData, inputData);
      }

      this.dataStore.data.errors = inputData.errors;
      this.dataStore.data.messages = inputData.messages;
      this.updateDataStore(
        _.merge(this.dataStore.data, inputData)
      );
    }
  }

  private updateDataStore(inputData: ItemResponse) {
    this.dataStore.data = inputData;
    this.data$.next(Object.assign({}, this.dataStore).data);
  }

  private isDataStoreEmpty() {
    return !this.data || this.dataStore.data === null;
  }

  clearData() {
    this.dataStore.data = null;
    this.data$.next(null);
  }


  setHttpCount(val) {
    this.count = val;
  }

  getHttpCount() {
    return this.count;
  }

  private mergeDeep(target: any, source: any) {
    const output = Object.assign({}, target);
    if (this.isObject(target) && this.isObject(source)) {
      Object.keys(source).forEach((key) => {
        if (this.isObject(source[key])) {
          if (!(key in target)) {
            Object.assign(output, { [key]: source[key] });
          } else {
            output[key] = this.mergeDeep(target[key], source[key]);
          }
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  }

  private isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  public getData() {
    return this.data$.getValue();
  }
}
