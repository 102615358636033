import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized-error',
  templateUrl: './unauthorized-error.component.html',
  styleUrls: ['./unauthorized-error.component.scss']
})
export class UnauthorizedErrorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
