import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { OptionItemHttpService } from './option-item-http.service';
import { OptionItem } from '../../fields/model/commom-model';

@Injectable()
export class OptionItemStoreService {
  activeMgaList: Observable<OptionItem[]>;
  private activeMgaListChanged$: BehaviorSubject<OptionItem[]>;

  adjusterList: Observable<OptionItem[]>;
  private adjusterListChanged$: BehaviorSubject<OptionItem[]>;

  companyList: Observable<OptionItem[]>;
  private companyListChanged$: BehaviorSubject<OptionItem[]>;

  countryList: Observable<OptionItem[]>;
  private countryListChanged$: BehaviorSubject<OptionItem[]>;

  countyByStateList: Observable<OptionItem[]>;
  private countyByStateListChanged$: BehaviorSubject<OptionItem[]>;

  coverageTypeList: Observable<OptionItem[]>;
  private coverageTypeListChanged$: BehaviorSubject<OptionItem[]>;

  civilRemedyAdjusterList: Observable<OptionItem[]>;
  private civilRemedyAdjusterListChanged$: BehaviorSubject<OptionItem[]>;

  dfsClaimAdjusterList: Observable<OptionItem[]>;
  private dfsClaimAdjusterListChanged$: BehaviorSubject<OptionItem[]>;

  legalPrefixesByClaimNumberList: Observable<OptionItem[]>;
  private legalPrefixesByClaimNumberListChanged$: BehaviorSubject<OptionItem[]>;

  lobList: Observable<OptionItem[]>;
  private lobListChanged$: BehaviorSubject<OptionItem[]>;

  lossCauseList: Observable<OptionItem[]>;
  private lossCauseListChanged$: BehaviorSubject<OptionItem[]>;

  lossSubCausesByLossCauseList: Observable<OptionItem[]>;
  private lossSubCausesByLossCauseListChanged$: BehaviorSubject<OptionItem[]>;

  paymentStatusList: Observable<OptionItem[]>;
  private paymentStatusListChanged$: BehaviorSubject<OptionItem[]>;

  productLineList: Observable<OptionItem[]>;
  private productLineListChanged$: BehaviorSubject<OptionItem[]>;

  productLineWithBothList: Observable<OptionItem[]>;
  private productLineWithBothListChanged$: BehaviorSubject<OptionItem[]>;

  propertyLossCauseList: Observable<OptionItem[]>;
  private propertyLossCauseListChanged$: BehaviorSubject<OptionItem[]>;

  statesByMgaTypeList: Observable<OptionItem[]>;
  private statesByMgaTypeListChanged$: BehaviorSubject<OptionItem[]>;

  usStateList: Observable<OptionItem[]>;
  private usStateListChanged$: BehaviorSubject<OptionItem[]>;

  private dataStore: {
    activeMgas: OptionItem[];
    adjusters: OptionItem[];
    companies: OptionItem[];
    countries: OptionItem[];
    countiesByState: OptionItem[];
    coverageTypes: OptionItem[];
    civilRemedyAdjusters: OptionItem[];
    dfsClaimAdjusters: OptionItem[];
    legalPrefixesByClaimNumber: OptionItem[];
    lobs: OptionItem[];
    lossCauses: OptionItem[];
    lossSubCausesByLossCause: OptionItem[];
    paymentStatuses: OptionItem[];
    productLines: OptionItem[];
    productLinesWithBoth: OptionItem[];
    propertyLossCauses: OptionItem[];
    statesByMgaType: OptionItem[];
    usStates: OptionItem[];
    // Add entry for each list
  };

  constructor(private httpService: OptionItemHttpService) {
    this.dataStore = {
      activeMgas: [],
      adjusters: [],
      companies: [],
      countries: [],
      countiesByState: [],
      coverageTypes: [],
      civilRemedyAdjusters: [],
      dfsClaimAdjusters: [],
      legalPrefixesByClaimNumber: [],
      lobs: [],
      lossCauses: [],
      lossSubCausesByLossCause: [],
      paymentStatuses: [],
      productLines: [],
      productLinesWithBoth: [],
      propertyLossCauses: [],
      statesByMgaType: [],
      usStates: [],
      // Initalize each array
    };

    this.activeMgaListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.activeMgaList = this.activeMgaListChanged$.asObservable();

    this.adjusterListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.adjusterList = this.adjusterListChanged$.asObservable();

    this.companyListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.companyList = this.companyListChanged$.asObservable();

    this.countryListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.countryList = this.countryListChanged$.asObservable();

    this.countyByStateListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.countyByStateList = this.countyByStateListChanged$.asObservable();

    this.coverageTypeListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.coverageTypeList = this.coverageTypeListChanged$.asObservable();

    this.civilRemedyAdjusterListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.civilRemedyAdjusterList = this.civilRemedyAdjusterListChanged$.asObservable();

    this.dfsClaimAdjusterListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.dfsClaimAdjusterList = this.dfsClaimAdjusterListChanged$.asObservable();

    this.legalPrefixesByClaimNumberListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.legalPrefixesByClaimNumberList = this.legalPrefixesByClaimNumberListChanged$.asObservable();

    this.lobListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.lobList = this.lobListChanged$.asObservable();

    this.lossCauseListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.lossCauseList = this.lossCauseListChanged$.asObservable();

    this.lossSubCausesByLossCauseListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.lossSubCausesByLossCauseList = this.lossSubCausesByLossCauseListChanged$.asObservable();

    this.paymentStatusListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.paymentStatusList = this.paymentStatusListChanged$.asObservable();

    this.productLineListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.productLineList = this.productLineListChanged$.asObservable();

    this.productLineWithBothListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.productLineWithBothList = this.productLineWithBothListChanged$.asObservable();

    this.propertyLossCauseListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.propertyLossCauseList = this.propertyLossCauseListChanged$.asObservable();

    this.statesByMgaTypeListChanged$ = new BehaviorSubject(
      []
    ) as BehaviorSubject<OptionItem[]>;
    this.statesByMgaTypeList = this.statesByMgaTypeListChanged$.asObservable();

    this.usStateListChanged$ = new BehaviorSubject([]) as BehaviorSubject<
      OptionItem[]
    >;
    this.usStateList = this.usStateListChanged$.asObservable();
  }
  loadProductLineOptionItems() {
    this.httpService.fetchActiveMgas().subscribe(
      (data) => {
        this.dataStore.activeMgas = data;
        this.activeMgaListChanged$.next(
          Object.assign({}, this.dataStore).activeMgas
        );
      },
      (error) => console.log('Could not getAll activeMgas.')
    );

    this.httpService.fetchAdjusters().subscribe(
      (data) => {
        this.dataStore.adjusters = data;
        this.adjusterListChanged$.next(
          Object.assign({}, this.dataStore).adjusters
        );
      },
      (error) => console.log('Could not getAll adjusters.')
    );

    this.httpService.fetchCompanyList().subscribe(
      (data) => {
        this.dataStore.companies = data;
        this.companyListChanged$.next(
          Object.assign({}, this.dataStore).companies
        );
      },
      (error) => console.log('Could not getAll companies.')
    );

    this.httpService.fetchCoverageTypes().subscribe(
      (data) => {
        this.dataStore.coverageTypes = data;
        this.coverageTypeListChanged$.next(
          Object.assign({}, this.dataStore).coverageTypes
        );
      },
      (error) => console.log('Could not getAll coverageTypes.')
    );

    this.httpService.fetchCivilRemedyAdjusters().subscribe(
      (data) => {
        this.dataStore.civilRemedyAdjusters = data;
        this.civilRemedyAdjusterListChanged$.next(
          Object.assign({}, this.dataStore).civilRemedyAdjusters
        );
      },
      (error) => console.log('Could not getAll civilRemedyAdjusters.')
    );

    this.httpService.fetchDfsClaimAdjusters().subscribe(
      (data) => {
        this.dataStore.dfsClaimAdjusters = data;
        this.dfsClaimAdjusterListChanged$.next(
          Object.assign({}, this.dataStore).dfsClaimAdjusters
        );
      },
      (error) => console.log('Could not getAll dfsClaimAdjusters.')
    );

    this.httpService.fetchLobs().subscribe(
      (data) => {
        this.dataStore.lobs = data;
        this.lobListChanged$.next(Object.assign({}, this.dataStore).lobs);
      },
      (error) => console.log('Could not getAll lobs.')
    );

    this.httpService.fetchLossCauses().subscribe(
      (data) => {
        this.dataStore.lossCauses = data;
        this.lossCauseListChanged$.next(
          Object.assign({}, this.dataStore).lossCauses
        );
      },
      (error) => console.log('Could not getAll lossCauses.')
    );
    this.httpService.fetchPropertyLossCauses().subscribe(
      (data) => {
        this.dataStore.propertyLossCauses = data;
        this.propertyLossCauseListChanged$.next(
          Object.assign({}, this.dataStore).propertyLossCauses
        );
      },
      (error) => console.log('Could not getAll propertyLossCauses.')
    );
  }
  // This can be called upon application load and all dropdowns will be available
  loadOptionItems() {
    this.httpService.fetchPaymentStatuses().subscribe(
      (data) => {
        this.dataStore.paymentStatuses = data;
        this.paymentStatusListChanged$.next(
          Object.assign({}, this.dataStore).paymentStatuses
        );
      },
      (error) => console.log('Could not getAll paymentStatuses.')
    );

    this.httpService.fetchProductLines().subscribe(
      (data) => {
        this.dataStore.productLines = data;
        this.productLineListChanged$.next(
          Object.assign({}, this.dataStore).productLines
        );
      },
      (error) => console.log('Could not getAll productLines.')
    );

    this.httpService.fetchGlobalChoices().subscribe((data) => {
      this.dataStore.countries = data['Country'];
      this.countryListChanged$.next(
        Object.assign({}, this.dataStore).countries
      );

      this.dataStore.usStates = data['USStates'];
      this.usStateListChanged$.next(Object.assign({}, this.dataStore).usStates);
    });
  }

  getCountiesByState(state: string) {
    console.log('state', state);
    this.httpService.fetchCountiesByStateList(state).subscribe(
      (data) => {
        this.dataStore.countiesByState = data;
        this.countyByStateListChanged$.next(
          Object.assign({}, this.dataStore).countiesByState
        );
      },
      (error) => console.log('Could not getAll countiesByState.')
    );
  }

  getLegalPrefixesByClaimNumber(claimNumber: string) {
    this.httpService.fetchLegalPrefixesByClaimNumber(claimNumber).subscribe(
      (data) => {
        this.dataStore.legalPrefixesByClaimNumber = data;
        this.legalPrefixesByClaimNumberListChanged$.next(
          Object.assign({}, this.dataStore).legalPrefixesByClaimNumber
        );
      },
      (error) => console.log('Could not getAll legalPrefixesByClaimNumber.')
    );
  }

  getLossSubCausesByLossCause(lossCauseId: string) {
    this.httpService.fetchLossSubCausesByLossCause(lossCauseId).subscribe(
      (data) => {
        this.dataStore.lossSubCausesByLossCause = data;
        this.lossSubCausesByLossCauseListChanged$.next(
          Object.assign({}, this.dataStore).lossSubCausesByLossCause
        );
      },
      (error) => console.log('Could not getAll lossSubCausesByLossCause.')
    );
  }

  getStatesByMgaType(mgaType: string) {
    this.httpService.fetchStatesByMgaType(mgaType).subscribe(
      (data) => {
        this.dataStore.statesByMgaType = data;
        this.statesByMgaTypeListChanged$.next(
          Object.assign({}, this.dataStore).statesByMgaType
        );
      },
      (error) => console.log('Could not getAll statesByMgaType.')
    );
  }
}
