import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {
  private scripts: any = {};

  constructor() {
    // tslint:disable-next-line: max-line-length
    const googleMapsApiUrl =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAi6Qq-IY1_YY20CS7LRlew0F6llm-Qaw4&libraries=places&language=en';
    // tslint:disable-next-line: max-line-length
    // const googleMapsApiUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyArtBHCo9qAMKU1SIpQeQLvXnv_QCwQX6Y&libraries=places&language=en';

    const ScriptStore: Scripts[] = [
      { name: 'googleMapsApi', src: googleMapsApiUrl }
    ];
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }
}
