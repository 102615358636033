import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { ApiConfigService } from '../shared-services/api-config.service';

@Injectable({
  providedIn: 'root',
})
export class ProductLineService {
  constructor(private apiConfigService: ApiConfigService) {}
  private readonly DEFAULT_PRODUCT_LINE = this.apiConfigService.getDefaultProductLine();
  private currentProductLine$ = new BehaviorSubject<string>(
    this.DEFAULT_PRODUCT_LINE
  );
  readonly currentProductLine = this.currentProductLine$.asObservable();

  private toggleButtonDisabled$ = new Subject<boolean>();
  readonly toggleButtonDisabled = this.toggleButtonDisabled$.asObservable();

  private showBothOption$ = new BehaviorSubject<boolean>(false);
  readonly showBothOption = this.showBothOption$.asObservable();

  setProductLine(productLine: string) {
    this.currentProductLine$.next(productLine);
    sessionStorage.setItem('productLine', productLine);
  }

  setShowBothOption(show: boolean) {
    this.showBothOption$.next(show);
  }

  setToggleButtonDisable(disable: boolean) {
    this.toggleButtonDisabled$.next(disable);
  }
}
