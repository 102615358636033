import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThigApiService {
  // private baseUrl = this.env.getUrl().citadel_url;
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private env: ApiConfigService) {}

  updateFieldsApi(req, api) {
    const apiUrl = `${this.baseUrl}${api}`;
    return this.http.post<any>(apiUrl, req);
  }
}
