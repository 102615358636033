<span (click)="onClickEvent()" matTooltip="{{toolTip}}">
  <div #buttonElement
       *ngIf="buttonStyle !== 'menuItem'"
       style="display: inline-block">
    <span *ngIf="menuContent"
          (mouseover)="triggeredByMouseHover ? matMenuTrigger.openMenu() : ''"
          #matMenuTrigger="matMenuTrigger"
          [matMenuTriggerData]="menuData"
          [matMenuTriggerFor]="menu">
      <button
        *ngIf="!icon"
        [ngClass]="getButtonMenuContentClasses()"
        [disabled]="disabled">
          {{buttonName}}
      </button>
      <ng-container
        *ngIf="icon">
        <ng-container
          *ngTemplateOutlet="iconButton">
        </ng-container>
      </ng-container>
      <mat-menu #menu="matMenu">
          <span (mouseleave)="triggeredByMouseHover ? matMenuTrigger.closeMenu() : ''">
            <ng-container *ngTemplateOutlet="menuContent context: {menuData: menuData}"></ng-container>
          </span>
      </mat-menu>
    </span>
    <button *ngIf="(buttonName || (!icon && !iconLocation && !spriteIcon)) && !menuContent"
            type="{{type}}"
            [ngClass]="[
          buttonStyle === 'reset' ? 'btn btn-small th-btn-outline-brand' :
          buttonStyle === 'search' || buttonStyle === 'custom-action' ? 'btn btn-small th-btn-default' :
          buttonStyle === 'save' || buttonStyle === 'next' || buttonStyle === 'add' ? 'btn btn-small btn-success' :
          buttonStyle === 'cancel' || buttonStyle === 'exit' ? 'btn btn-small btn-danger' :
          buttonStyle === 'disabled' ? 'btn btn-small btn-disabled' :
           'btn btn-small btn-default'
        ]"
            [name]="name"
            [class]="classes"
            [disabled]="disabled">
      <mat-icon
        *ngIf="icon">
        {{ icon }}
      </mat-icon>
      {{buttonName}}
    </button>
    <span
      *ngIf="(!buttonName && (icon || iconLocation)) && !menuContent">
      <ng-container
        *ngTemplateOutlet="iconButton">
      </ng-container>
    </span>
  </div>
  <button
    *ngIf="buttonStyle === 'menuItem'"
    [disabled]="disabled"
    mat-menu-item>
    <span *ngIf="icon || iconLocation">
      <mat-icon
        [svgIcon]="iconLocation"
        [ngStyle]="{'transform':'scale(' + iconSize + ')', 'color' : iconColor}">
        {{ icon }}
      </mat-icon>
    </span>
    {{ buttonName }}
  </button>
  <img *ngIf="spriteIcon"
       [ngClass]="[
            'hand-pointer',
            'icon',
            spriteIcon,
            disabled ? 'cursor-disabled' : 'hand-pointer'
       ].join(' ')"
       src="../../../../assets/images/ghost.gif">
</span>

<ng-template
  #iconButton>
  <mat-icon
    [ngStyle]="{'transform':'scale(' + iconSize + ')', 'color' : iconColor}"
    [svgIcon]="iconLocation"
    [class]="disabled ? 'disabledIcon' : 'hand-pointer'">
    {{ icon }}
  </mat-icon>
</ng-template>
