import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {merge, Subject, Subscription} from 'rxjs';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {MatSort} from '@angular/material/sort';
import {map} from 'rxjs/operators';
import {ReadOnlyFieldData} from '../../fields/controls/text-readonly-field/text-readonly-field.component';
import {SelectionModel} from '@angular/cdk/collections';
import {DynamicPipe} from '../../fields/pipes/dynamic.pipe';
import {UniversalTableDataConverterService} from './universal-table-data-converter.service';

@Component({
  selector: 'app-universal-table',
  templateUrl: './universal-table.component.html',
  styleUrls: ['./universal-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({
          height: '0px',
          minHeight: '0',
          margin: '0px',
        })
      ),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class UniversalTableComponent implements OnInit, OnDestroy {

  @Input() set columnDefs(value: ColumnDefinition[]) {
    this._columnDefs = value;
    if (this.dataSourceBuffer && this.dataSourceBuffer.length > 0) {
      this.initializeTableData(this.dataSourceBuffer);
    }
  }

  private dataSourceBuffer: any[];
  @Input() set dataSource(value: any[] | any) {
    if (value) {
      value = this.clone(value);
      this.dataSourceBuffer = value;
      if (this._columnDefs) {
        this.initializeTableData(this.dataSourceBuffer);
      }
    }
  }

  @Input() defaultPageSize: number = 10;
  @Input() hidePagination: boolean = false;

  /**
   * If no data is present in the table, then instead of the table, this message will be displayed.
   */
  @Input() noDataMessage: string = '';

  /**
   * Blue bold font that appears over the table.
   */
  @Input() tableTitle: string = '';

  /**
   * Value used to filter out non-matching rows in the table.
   */
  @Input() showFilter = false;

  /**
   * If a value is assigned a count of how many records will appear just above the table.
   */
  @Input() totalRecordCountLabel: string;

  /**
   * Required if record count label is specified. provide the value that corresponds with the label.
   */
  @Input() totalRecordCount: number;

  /**
   * For help with front end performance you can tell angular how to distinguish table rows from one another.
   * This value should represent name of the key in each row that is unique (e.g. "id").
   */
  @Input() trackByDataKey: string;

  /**
   * Needed if row selection is desired. Multiple row selection is dependant on state of data.
   */
  @Input() selectionDef: SelectionDefinition;

  /**
   * Sets weather the user is able to collapse the table or not. Collapse icon cannot be seen if true.
   */
  @Input() removeCollapseTableButton = false;
  @Input() setPageToZeroTrigger: Subject<boolean>;
  @Input() hidePageSize = false;
  @Input() expandedRowDefs: ReadOnlyFieldData[];

  @Output() sortOrPageChanged = new EventEmitter<SortOrPageChangeEvent>();

  /**
   * Will emmit the row data for which ever row is clicked on.
   */
  @Output() currentSelection = new EventEmitter<any[]>();
  @Output() rowExpandedNotification = new EventEmitter<any>();
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  private subscriptions = new Subscription();
  displayedColumns: Array<string>;
  collapse = false;
  expandedElement: any;
  matTableDataSource = new MatTableDataSource<any>();
  pageSizeOptions = [10, 20, 30];
  _columnDefs: ColumnDefinition[];
  pageSize: number;
  selection = new SelectionModel<any>();
  get columnDefs() {
    this.displayedColumns = this._columnDefs.filter(def => {
      return !def.hiddenColumnCondition;
    }).map((c) => {
      if (this.isCustomDataMappingInUse(c)) {
        return this.getExplicitDataMappingColumnName(c);
      } else {
        return c.columnName;
      }
    });
    if (this.expandedRowDefs) {
      this.displayedColumns.unshift('collapseIcon');
    }
    return this._columnDefs;
  }

  constructor(
      private dynamicPipe: DynamicPipe,
      private universalTableDataConverterService: UniversalTableDataConverterService
      ) {
  }

  ngOnInit(): void {
    this.initializeRowSelection();
    this.matTableDataSource.sort = this.sort;
    this.matTableDataSource.paginator = this.paginator;

    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.setPaginatorPageToZero();
      })
    );

    if (this.setPageToZeroTrigger) {
      this.subscriptions.add(
        this.setPageToZeroTrigger.subscribe((execute) => {
          if (execute === true) {
            this.setPaginatorPageToZero();
          }
        })
      );
    }

    this.subscriptions.add(
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          map(() => {
            this.sortOrPageChanged.emit({
              sortColumn: this.sort.active,
              sortDirection: this.sort.direction,
              paginatorPage: this.paginator.pageIndex,
            });
          })
        )
        .subscribe()
    );
  }

  private initializeTableData(value: any) {
    const arrayFormat = Array.isArray(value) ? value : this.universalTableDataConverterService.ruleServiceNodeToArray(value);
    const mappedData = this.mapTableData(arrayFormat);

    this.matTableDataSource.data = mappedData;
    this.dataSourceBuffer = [];
  }

  private mapTableData(data: any[]): any[] {

    const dataNodeKeysToMap: string[] = this.getDataNodeKeysToMap(this._columnDefs);
    dataNodeKeysToMap.forEach(dataNodeKeyToMap => {
      data.forEach(row => {
        row[dataNodeKeyToMap] = this.extractDisplayValue(row[dataNodeKeyToMap], dataNodeKeyToMap);
      });
    });

    const columnDefsToMapWithCustomMapping = this.getColumnDefsToMapWithCustomMapping(this._columnDefs);
    columnDefsToMapWithCustomMapping.forEach(columnDef => {
      if (!Array.isArray(columnDef.explicitDataMapping)) {
        columnDef.explicitDataMapping = columnDef.explicitDataMapping as ExplicitDataMapping;
        if (!Array.isArray(columnDef.explicitDataMapping.dataKey)) {
          columnDef.explicitDataMapping.dataKey = [columnDef.columnName, columnDef.explicitDataMapping.dataKey] as string[];
        } else {
          columnDef.explicitDataMapping.dataKey.splice(0, 0, columnDef.columnName);
        }
        columnDef.explicitDataMapping = [columnDef.explicitDataMapping] as ExplicitDataMapping[];
      } else {
        columnDef.explicitDataMapping.forEach(mapping => {
          if (!Array.isArray(mapping.dataKey)) {
            mapping.dataKey = [mapping.dataKey] as string[];
          }
        });
      }
      const newColumnName = this.getExplicitDataMappingColumnName(columnDef);
      data = data.map(row => {
        return {
          ...row,
          [newColumnName]: this.customMap(
              row,
              columnDef.explicitDataMapping as ExplicitDataMapping[],
              columnDef.columnName
          )
        }
      });
    });

    return data;
  }


  private setPaginatorPageToZero() {
    this.paginator.firstPage();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  provideSelectionToParent(row: any) {
    /**
     * selection is enabled if nonSelectableBooleanKey is not configured or if it is set to false
     * so selection is disabled only if nonSelectableBooleanKey is explicitly set to true
     */
    if (this.selectionDef) {
      if (!this.selectionDef.nonSelectableBooleanKey ||
        row[this.selectionDef.nonSelectableBooleanKey] === false) {
        this.selection.toggle(row);
        if (this.selectionDef?.selectionType === 'multiSelect') {
          this.selection.isSelected(row)
            ? (row[
              this.selectionDef.propertyKey
              ] = this.selectionDef.selectedValue)
            : (row[
              this.selectionDef.propertyKey
              ] = this.selectionDef.unSelectedValue);
        }
        if (this.selection.selected) {
          this.currentSelection.emit(row);
        } else {
          this.currentSelection.emit([]);
        }
      }
    }
  }

  onExpandRowButtonClicked(rowData: any) {
    this.rowExpandedNotification.emit(rowData);
    if (this.expandedElement === rowData) {
      this.expandedElement = null;
    } else {
      this.expandedElement = rowData;
    }
  }

  rowSelectionFormat(row) {
    return this.selection.isSelected(row) ? {highlighted: true} : {};
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filterValue.trim().toLowerCase();
    this.setPaginatorPageToZero();
  }

  trackRow(index: number, item: any): any {
    return item[this.trackByDataKey];
  }

  isTableDataPresent() {
    return this.matTableDataSource?.data?.length > 0;
  }

  getCellContent(col: ColumnDefinition, element: any): string {
    let cellValue = element[col.columnName];

    if (this.isCustomDataMappingInUse(col)) {
      cellValue = element[this.getExplicitDataMappingColumnName(col)];
    }

    return col.displayMask ? this.dynamicPipe.transform(cellValue, col.displayMask) : cellValue;
  }

  isCustomDataMappingInUse(c: ColumnDefinition) {
    return c.explicitDataMapping && typeof c.explicitDataMapping !== 'boolean';
  }

  getReadOnlyCellContent(col: ReadOnlyFieldData, element: any): string {
    let cellValue;
    if (typeof col.displayValue === 'string') {
      if (element[col.displayValue] === undefined || element[col.displayValue] === null) {
        return '';
      } else {
        cellValue = element[col.displayValue];
      }
    } else if (Array.isArray(col.displayValue)) {
      cellValue = this.unpackData(element, col.displayValue);
    }
    if (typeof cellValue !== 'string' && typeof cellValue !== 'number') {
      if ('displayValue' in cellValue) {
        cellValue = cellValue.displayValue;
      } else {
        console.error('Universal table could not locate a valid string value to display for column: --> ', col.displayValue.toString());
      }
    }
    return col.displayValue ? this.dynamicPipe.transform(cellValue, col.type) : cellValue;
  }

  private unpackData(data: any, dataKeys: string[]): string {
    let unpackedData = dataKeys.reduce((result: any, dataKey: string) => {
      return result && result[dataKey];
    }, data);
    return unpackedData;
  }

  getExplicitDataMappingColumnName(col: ColumnDefinition): string {
    if (!this.arrayConversionComplete(col.explicitDataMapping)) {
      col.explicitDataMapping = col.explicitDataMapping as ExplicitDataMapping;
      if (Array.isArray(col.explicitDataMapping.dataKey)) {
        return col.columnName + col.explicitDataMapping.dataKey.join('');
      }
      return col.columnName + col.explicitDataMapping.dataKey;
    }
    col.explicitDataMapping = col.explicitDataMapping as ExplicitDataMapping[];
    return col.explicitDataMapping.map(mapping => {
      mapping.dataKey = mapping.dataKey as string[];
      return mapping.dataKey.join('');
    }).join('');
  }

  private arrayConversionComplete(explicitDataMapping: boolean | ExplicitDataMapping | ExplicitDataMapping[]) {
    let result = true;
    if (!Array.isArray(explicitDataMapping)) {
      result = false;
    } else {
      explicitDataMapping.forEach(mapping => {
        if (!Array.isArray(mapping.dataKey)) {
          result = false;
        }
      });
    }
    return result;
  }

  private getDataNodeKeysToMap(columnDefs: ColumnDefinition[]): string[] {
    return columnDefs.filter(columnDef => columnDef.explicitDataMapping === undefined || columnDef.explicitDataMapping === false)
        .map(columnDef => columnDef.columnName);
  }

  private getColumnDefsToMapWithCustomMapping(columnDefs: ColumnDefinition[]): ColumnDefinition[] {
    return columnDefs.filter(columnDef => {
      return this.isCustomDataMappingInUse(columnDef);
    });
  }

  private customMap(row: any, explicitDataMapping: ExplicitDataMapping[], columnName: string): string {
    return explicitDataMapping.map(mapping => {
      return this.customMapObject(row, mapping, columnName);
    }).join('');
  }


  private extractDisplayValue(value: any, columnName: string): string {
    if (value === undefined || value === null) {
      return '';
    }
    if (
        typeof value !== 'string' &&
        typeof value !== 'number' &&
        typeof value !== 'boolean'
    ) {
      if ('displayValue' in value) {
        return value.displayValue as string;
      } else {
        console.error('Universal table could not locate a valid string value to display for column: --> ', columnName);
      }
    } else {
      return value as string;
    }
  }

  private customMapObject(row: any, explicitDataMapping: ExplicitDataMapping, columnName: string) {
    const result: string[] = [];
    result.push(explicitDataMapping.prefix ? explicitDataMapping.prefix : '');
    explicitDataMapping.dataKey = explicitDataMapping.dataKey as string[];
    result.push(
        this.extractDisplayValue(
            this.unpackData(row, explicitDataMapping.dataKey),
            columnName
        )
    );
    result.push(explicitDataMapping.suffix ? explicitDataMapping.suffix : '');
    return result.join('');
  }

  private clone(value: any[] | any) {
    return Array.isArray(value) ? value.slice() : {...value};
  }

  private initializeRowSelection() {
    if (this.selectionDef) {
      const selectedRows = [];
      this.matTableDataSource.data.forEach((row) => {
        if (row[this.selectionDef.propertyKey] === this.selectionDef.selectedValue) {
          selectedRows.push(row);
        }
      });
      this.selection = new SelectionModel<any>(
          this.selectionDef?.selectionType === 'multiSelect',
          selectedRows
      );
    }
  }
}

export interface SortOrPageChangeEvent {
  sortColumn: string;
  sortDirection: string;
  paginatorPage: number;
}

// The columns will appear in the same order as they do in this array from left to right
// Due to life cycle constraints, if a template is used then column definitions have to be defined within the view where
// the tabs exist. See /src/app/home/claims-grid.html for an example definition.
export class ColumnDefinition {
  constructor(
    // Specifying a string will make universal table look for the node among the members of each dataSource element.
    // If a member is found, but the member is not of type string, then the table will assume it is a FieldConfig object and attempt to
    // display the displayValue property.
    // Specifying a string array will cause universal table to dig for the data using each element in the array as a key. Once the member
    // matching the current element is found, the next element will be used to search for children of this member.
    public columnName: string,
    public columnLabel: string,
    public columnTemplate: TemplateRef<any> = null,
    public displayMask: string = null,
    public hiddenColumnCondition: boolean = false,
    public rightAlign: boolean = false,
    /**
     * Universal Table needs to map data for sorting purposes. See full explanation here:
     * https://github.thig.com/nextgen/citadel-ui/pull/484
     */
    public explicitDataMapping: boolean | ExplicitDataMapping | ExplicitDataMapping[] = false
  ) {
  }
}

export class SelectionDefinition {
  constructor(
    public selectionType: selectionType,
    public propertyKey: string = null,
    public selectedValue: any = null,
    public unSelectedValue: any = null,
    public nonSelectableBooleanKey: string = null
  ) {
  }
}

export interface ExplicitDataMapping {
  prefix?: string;
  dataKey: string | string[];
  suffix?: string;
}


type selectionType = 'singleSelect' | 'multiSelect';
