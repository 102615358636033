import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerComponent } from './error-handler.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class ErrorHandlerService {
    durationInSeconds = 5;
    snackBarRef: any;

    constructor(private snackBar: MatSnackBar, private router: Router) {
      router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          if (this.snackBarRef) {
            this.snackBarRef.dismiss();
          }
        });
    }

    errorBar(err: any): void {
      if (null !== err && undefined !== err && (err.length > 0 || Object.keys(err).length > 0)) {
        if (Array.isArray(err)) {
          this.displaySnackBar(err, 'error');
        } else {
          this.displaySnackBar([err], 'error');
        }
      }
    }

    messageBar(msg: string[]): void {
      // console.log('msg', msg);
      if (null !== msg && msg.length > 0) {
        this.displaySnackBar(msg, 'message');
      }
    }

    fieldErrorBar(fieldErrs: Map<string, string[]>): void {
      if (null !== fieldErrs && undefined !== fieldErrs && (fieldErrs.size > 0 || Object.keys(fieldErrs).length > 0)) {
        const fieldErrors: Map<string, string[]> = new Map<string, string[]>();
        // tslint:disable-next-line: forin
        for (const fieldErr in fieldErrs) {
          fieldErrors.set(fieldErr, fieldErrs[fieldErr]);
        }
        // Array.from(fieldErrors.values()).forEach(value => console.log(value));
        this.errorBar(Array.from(fieldErrors.values()));
      }
    }

    private displaySnackBar(message: any[], panelClass: string) {
      // console.log('displaySnackBar', message, panelClass);
      if (null !== message && null !== panelClass) {
        this.snackBarRef = this.snackBar.openFromComponent(ErrorHandlerComponent, {
          duration: this.durationInSeconds * 1000,
          data: message,
          verticalPosition: 'top',
          panelClass
        });
      }
    }
  }
