<footer>
  <div class="col-md-12 footer">
    <img src="../../assets/images/footer-icon.png" alt="tower" class="center" width="40" height="40">
    <div class="footer-container" *ngIf="!hideSessionInfo">
      <div class="footer-wrapper">
        <div class="footer-info">
          <div><em>Look to the Tower.®</em></div>
          <div>
            Date:  {{date}}</div>
          <div>
            Session:  {{sessionId}}</div>
        </div>
        <div class="footer-logo">
          <div class="footer-copyright">
            <em> 2001 - {{year}} ©
              <a href="#" target="_blank" class="color-regalBlue">Tower Hill Insurance Group, LLC.</a> All rights reserved.
              <div>
                This website contains proprietary information of Tower Hill Insurance Group, LLC and its affiliates.
              </div>
            </em>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
