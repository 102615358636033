import { Component, OnDestroy, OnInit} from '@angular/core';
import { SpinnerService } from './common/spinner/spinner.service';
import { BrowserBackService } from './shared-services/browser-back.service';
import { Alert } from './common/alert/alert.constant';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'citadel-ui';
  showLoader: boolean;

  constructor(
    private spinner: SpinnerService,
    private browserBack: BrowserBackService,

  ) {}

  ngOnInit() {
    this.spinner.status.subscribe((val: boolean) => {
      setTimeout(() => {
        this.showLoader = val;
      }, 10);
    });
    this.browserBack.browserHistory();
  }
}
