import {Component, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, OnChanges, SimpleChanges} from '@angular/core';
import {FieldConfig} from '../../model/field-config';
import {UpdatedFieldsService} from '../../helpers/updated-fields.service';
import {LastChancePromptServiceService} from './last-chance-prompt/last-chance-prompt-service.service';
import {DataType} from './last-chance-prompt/last-chance-prompt.component';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() type: string;
  @Input() closeOnly: boolean = false;
  @Input() classes: string;
  @Input() id: string;
  @Input() name: string;
  @Input() icon: string;

  @Input() set iconLocation(value: string) {
    if (value && !this.iconLocation) {
      this._iconLocation = value;
      this.matIconRegistry.addSvgIcon(
        value,
        this.domSanitizer.bypassSecurityTrustResourceUrl(value)
      );
    }
  };

  get iconLocation(): string {
    return this._iconLocation;
  }

  private _iconLocation: string;
  @Input() iconSize: number = .8;
  @Input() iconColor: string = 'black';
  @Input() spriteIcon: string;

  @Input() buttonName: string;
  @Input() buttonStyle: buttonStyle = 'default';
  @Input() isFormValid: boolean;
  @Input() dataLossWarningPrompt = true;
  @Input() forceShowDataLossWarningPrompt = false;
  @Input() shouldAlertServer = true;
  @Input() customWarningPromptMessage;
  @Input() menuContent: TemplateRef<any>;
  @Input() menuData: any;
  @Input() matMenuClass: string;
  @Input() triggeredByMouseHover: boolean = false;
  @Input() toolTip: string;
  @Input() centerWarningPromptMessage = false;
  private _fieldConfig: FieldConfig;
  private readonly _doubleClickWindowMillis = 1000;

  // Since we disable the button after it is clicked and enable it after a certain 'x' ms window to prevent accidental
  // double clicks, any changes affecting the input 'disabled' flag within the 'x' ms window will have to be preserved
  // so that we can set the value of 'disabled' to whatever the component intends it to be.
  // This particular scenario has been observed to happen with the 'Next' buttons in steppers since multiple components(steps)
  // use the same 'Next' button.
  private _interimDisabled = false;

  @Input() set fieldConfig(value: FieldConfig) {
    if (value) {
      this._fieldConfig = value;
      this.setButtonName(value);
      if (this.disabledOverride === false) {
        value.readOnly === true ? this.disabled = true : this.disabled = false;
      }
    }
  }

  private setButtonName(value: FieldConfig) {
    if (!this.buttonName && value?.label) {
      // this.buttonName = value.label;
      this.buttonName = this.fieldConfig.label ? this.fieldConfig.label : value.label;
    }
  }

  get fieldConfig() {
    return this._fieldConfig;
  }
  @Output() onClick = new EventEmitter<boolean>();

  @Input() disabled = false;

  _disabledOverride = false;

  @Input() set disabledOverride(value: boolean) {
    this._disabledOverride = value;
    if (this._disabledOverride === true) {
      this.disabled = true;
    }
  }

  get disabledOverride() {
    return this._disabledOverride;
  }

  @ViewChild('buttonElement') buttonElement: ElementRef;

  constructor(
    private updatedFieldsService: UpdatedFieldsService,
    private lastChancePromptService: LastChancePromptServiceService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      this._interimDisabled = changes.disabled.currentValue;
    }
  }

  onClickEvent() {
    if (!this.disabled) {
      if (this.fieldConfig) {
        // Rules-service button use case
        this.notifyServer();
      } else {
        // Non rules-service button use case
        this.onClick.emit();
      }
      this.disableAndEnableButton();
    }
  }

  // Disable immediately and enable the button in a certain amount of time to prevent multiple clicks
  disableAndEnableButton() {
    // Disabling the button without a setTimeout works fine for the buttons where type=button is used.
    // For buttons with type=submit, disabling the button in the same event loop iteration prevents the
    // submit function from being called. Using a setTimeout disables the button after the current event loop.
    // So this should work for all button types.
    setTimeout(() => {
      this.disabled = true;
    }, 0);

    // Enable the button after some time
    setTimeout(() => {
      this.disabled = this._interimDisabled;
    }, this._doubleClickWindowMillis);
  }

  notifyServer() {
    if (this.fieldConfig?.tokenString.includes('save') || this.fieldConfig?.tokenString.includes('next')
      || this.fieldConfig?.tokenString.includes('submit')) { // Save button use case
      if (this.isFormValid === undefined) {
        console.error('Button attribute: [isFormValid] is required for save tokens');
      } else {
        if (this.isFormValid) {
          // onClick will emmit 'server response object' if the server handles the request successfully
          // (e.g. form is valid and no network errors).
          // onClick will emmit 'false' if the form is invalid,
          // or will emmit 'server response object, with errors' if request fails server validation,
          // or will emmit null if the server fails to process the request (e.g. network error).
          this.alertServer();
        } else {
          const saveStatus = false;
          this.onClick.emit(saveStatus);
        }
      }
    } else if (this.fieldConfig?.tokenString.includes('discard')) { // Discard button use case
      if ((this.dataLossWarningPrompt && this.updatedFieldsService.updatedFieldsQueue.length > 0) || this.forceShowDataLossWarningPrompt) {
        // Provides the user with a second chance
        // onClick will emmit 'true' means it is safe to proceed with changes to state (e.g. closing modal, changing page).
        // onClick will emmit 'false' means the user has changed his/her mind about discarding
        this.displayLastChancePrompt('formData', true, this.centerWarningPromptMessage);
      } else {
        this.updatedFieldsService.resetUpdateFields();
        this.alertServer();
      }
    } else if (this.fieldConfig?.tokenString.includes('remove')) { // Delete button use case
      // Provides the user with a second chance
      // onClick will emmit 'true' means the data has been deleted
      // onClick will emmit 'false' means the user has changed his/her mind about deleting
      this.displayLastChancePrompt('tableData', false);
    } else { // Default use case
      // onClick will emmit 'server response object' if the server handles the request successfully (e.g. http 200 code).
      // onClick will emmit 'null' if server fails to process the request (e.g. network error).
      this.alertServer();
    }

  }

  private displayLastChancePrompt(dataType: DataType, resetUpdatedFields: boolean, centerWarningPromptMessage: boolean = false) {
    const buttonRect = this.buttonElement.nativeElement.getBoundingClientRect();
    const toolTipPosition = {left: `${buttonRect.left}px`, top: `${buttonRect.top - buttonRect.height * 2}px`};
    this.lastChancePromptService.open(toolTipPosition, dataType, this.customWarningPromptMessage, this.closeOnly, centerWarningPromptMessage);
    this.lastChancePromptService.confirmed()
      .subscribe(res => {
        if (res) {
          if (resetUpdatedFields) {
            this.updatedFieldsService.resetUpdateFields();
          }
          this.alertServer();
        } else {
          this.onClick.emit(res);
        }
      });
  }

  alertServer() {
    if (this.shouldAlertServer) {
      this.updatedFieldsService.setUpdatedFields(
        this.fieldConfig.value,
        this.fieldConfig.tokenString,
        true
      ).subscribe(requestStatus => {
        this.onClick.emit(requestStatus);
      });
    } else {
      this.onClick.emit(true);
    }
  }

  getButtonMenuContentClasses() {
    let classes = [];
    classes.push('hand-pointer');
    classes.push('dropdown-toggle');
    classes.push('btn-small')

    if (this.classes) {
      this.classes.split(' ').forEach(c => {
        classes.push(c);
      });
    } else {
      classes.push('btn');
      classes.push('btn-outline-secondary');
    }

    return classes.join(' ');
  }
}

type buttonStyle =
  'default' |
  'reset' |
  'search' |
  'custom-action' |
  'add' |
  'save' |
  'cancel' |
  'exit' |
  'disabled' |
  'next' |
  'menuItem';
