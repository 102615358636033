import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  logout() {
    window.sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/logout';
  }
}
