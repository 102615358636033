import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'mask'
})
export class DynamicPipe implements PipeTransform {
  constructor(private decimal: DecimalPipe,
              private currency: CurrencyPipe) {}

  transform(value: any, pipeToken?: any, pipeArgs?: any): any {
    if (!pipeToken) {
      return value;
    }
    if (value && pipeToken === 'phone') {
      value = value.replace(/[^0-9]/g, '');
      if (value.length > 3 && value.length < 6) {
        return (value = '(' + value.slice(0, 3) + ') ' + value.slice(3, 5));
      }

      if (value.length > 5 && value.length < 7) {
        return (value = '(' + value.slice(0, 3) + ') ' + value.slice(3, 6));
      }

      if (value.search('\\)') === -1 && value.length > 6) {
        return (value = [
          '(' + value.slice(0, 3),
          ') ',
          value.slice(3, 6),
          '-',
          value.slice(6, 10)
        ].join(''));
      }
    }
    if (value && pipeToken === 'FEIN') {
      if (value.length === 2) {
        value += '-';
      }
      if (value.search('-') === -1 && value.length > 2) {
        value = [value.slice(0, 2), '-', value.slice(2, 10)].join('');
      }
    }
    if (value && pipeToken === 'currency') {
      value = this.currency.transform(value);
    }
    if (value && pipeToken === 'number') {
      value = value.replace(/[^0-9]/g, '');
      value = this.decimal.transform(value, '1.0-0');
    }
    return value;
  }
}
