import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { ComponentValueChangeListner } from '../classes/data-config-update';
import { DataStorageService } from 'src/app/shared-services/data-storage.service';
import { ThigApiService } from 'src/app/shared-services/thig-api.service';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdatedFieldsService implements ComponentValueChangeListner {
  public updatedFieldsList = [];
  public updatedFieldsQueue = [];
  apiUrl: string;
  subSessionId: any;

  constructor(
    private dataStorageService: DataStorageService,
    private thigApiService: ThigApiService
  ) {
    this.apiUrl = 'alert-svc-update.json';
  }

  onValueChangeComplete(value: any, tokenId: string, alertServer: boolean) {
    this.setUpdatedFields(value, tokenId, alertServer);
  }

  setUpdatedFields(
    val: any,
    tokenString: string,
    alertServer: string | boolean
  ): Observable<boolean> {
    if (!tokenString) {
      console.log(
        'Token string not provided for setUpdatedFields method. \n ' +
          'You must provide a token string for this method to work as intended.'
      );
      return;
    }
    if (
      this.updatedFieldsList &&
      this.isNotInUpdatedFieldsListAlready(tokenString)
    ) {
      this.updatedFieldsList.push(tokenString);
    } else {
      this.removePayloadFromQueue(tokenString);
    }
    this.updatedFieldsQueue.push(this.createPayLoad(val, tokenString));
    if (alertServer === 'true' || alertServer === true) {
      // this.actionName = tokenString;
      const req = this.constructApiRequest(this.updatedFieldsQueue);
      let successNotice = new Subject<any>();
      this.thigApiService.updateFieldsApi(req, this.apiUrl).subscribe(
        (res) => {
          if (res) {
            this.dataStorageService.setData(res);
            this.resetUpdateFields();
            successNotice.next(res);
            // Temporary change, ask server team to correctly send error codes
            // if (res['errors'].length === 0) {
            //   this.dataStorageService.setData(res);
            //   this.resetUpdateFields();
            //   successNotice.next(res);
            // } else {
            //   successNotice.next(null);
            // }
          }
        },
        (error) => {
          // TODO May want the successNotice to emmit the error instead of null in the future if that turns out to be more helpful
          successNotice.next(null);
        }
      );
      return successNotice.asObservable().pipe(take(1));
    }
  }

  public removePayloadFromQueue(tokenString: string) {
    let tempQueue = _.cloneDeep(this.updatedFieldsQueue);
    tempQueue.forEach((payload, index) => {
      if (
        payload &&
        payload.hasOwnProperty('tokenString') &&
        payload.tokenString === tokenString
      ) {
        this.updatedFieldsQueue.splice(index, 1);
      }
    });
  }

  private createPayLoad(val, token) {
    return {
      val,
      tokenString: token,
      timeStamp: new Date().getTime(),
    };
  }

  private constructApiRequest(changes) {
    return {
      // actionName: actionName,
      // subSessionId: this.subSessionId,
      changes,
    };
  }

  private isNotInUpdatedFieldsListAlready(tokenString: string): boolean {
    return this.updatedFieldsList.indexOf(tokenString) === -1;
  }

  getUpdatedFields() {
    return this.updatedFieldsQueue;
  }

  resetUpdateFields() {
    this.updatedFieldsQueue = [];
  }

  setApiUrl(url: string) {
    this.apiUrl = url;
  }

  getApiUrl() {
    return this.apiUrl;
  }
}
