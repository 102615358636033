import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash-es';
import { MaskedInputType } from '../model/commom-model';

@Pipe({
  name: 'maskInput'
})
export class MaskInputPipe implements PipeTransform {

  transform(value: string, maskInputType: MaskedInputType, maxLength: number): string {
    if (!value || value == null || !maskInputType) {
      return;
    }
    // covert number to string.
    value = isNumber(value) ? '' + value : value;
    if (value && maskInputType === 'phone') {
      value = value.replace(/[^0-9]/g, '');
      if (value.length > 3 && value.length < 6) {
        return (value = '(' + value.slice(0, 3) + ') ' + value.slice(3, 5));
      }

      if (value.length > 5 && value.length < 7) {
        return (value = '(' + value.slice(0, 3) + ') ' + value.slice(3, 6));
      }

      if (value.search('\\)') === -1 && value.length > 6) {
        return (value = [
          '(' + value.slice(0, 3),
          ') ',
          value.slice(3, 6),
          '-',
          value.slice(6, 10)
        ].join(''));
      }
    }
    if (value && maskInputType === 'FEIN') {
      if (value.length === 2) {
        value += '-';
      }
      if (value.search('-') === -1 && value.length > 2) {
        value = [value.slice(0, 2), '-', value.slice(2, 10)].join('');
      }
    }
    if (value && maskInputType === 'zipCode') {
      console.log('Max length: ', maxLength);
      if (value.length > maxLength) {
        return value.slice(0, maxLength);
      }
    }
    return value;


  }

}
