<div class="row th-height-90 justify-content-center">
    <div class="col-xl-6 col-lg-10 mt-5 kt-wizzard-custom-content-mobile">
        <div class="kt-wizzard-custom-content-body overflow-visible bg-white pb-4 pt-4">
            <div class="container px-0">
                <div class="kt-wizzard-custom-content-body__section">
                    <h5 class="font-weight-bold mb-3">401 - Unauthorized</h5>
                    <div>Your authorization failed. Please try again</div>
                </div>
            </div>
        </div>
    </div>
</div>