import { Component, Input, OnInit } from '@angular/core';
import {ApiConfigService} from '../shared-services/api-config.service';

@Component({
  selector: 'thig-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  sessionId: string | null;
  date: Date;
  year: number;
  @Input() hideSessionInfo:boolean =false;

  constructor(private apiConfigService: ApiConfigService) { }

  ngOnInit() {
    this.sessionId = this.apiConfigService.getSessionId();
    this.date = new Date();
    this.year = this.date.getUTCFullYear();
  }
}
