import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { UnauthorizedErrorComponent } from './unauthorized-error/unauthorized-error.component';

const routes: Routes = [
  {
    path: 'quickFnol',
    loadChildren: () => import('./quick-fnol/quick-fnol.module').then((m) => m.QuickFnolModule),
  },
  {path: 'fnolError', component: UnauthorizedErrorComponent},
  { path: '401', component: UnauthorizedErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
