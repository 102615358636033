import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LogoutService} from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class CitadelServiceSessionManagerService {
  private serverSessionInactivityTimeoutInSeconds: number;
  private clientSessionInactivityTimeoutInSeconds: number;
  private _lastContactWithServer: number;

  //This value is to ensure the client session always times out before the server session to preserve the user experience.
  private clientTimeoutBufferInSeconds = 300;

  constructor(
      private http: HttpClient,
      private logoutService: LogoutService,
  ) {}

  setStartingValues(serverSessionTimeout: number) {
    this.serverSessionInactivityTimeoutInSeconds = serverSessionTimeout;
    this.clientSessionInactivityTimeoutInSeconds =
        this.serverSessionInactivityTimeoutInSeconds - this.clientTimeoutBufferInSeconds;
  }

  getServerSessionTimeoutInSeconds() {
    const url = environment.baseUrl + '/api/security/getSessionTimeout';
    return this.http.post<string>(url, {});
  }

  set lastContactWithServer(timeInMilliseconds: number) {
    this._lastContactWithServer = timeInMilliseconds;
  }

  startSessionManagerAgent() {
    let testInterval = 5000;
    const sessionManagerAgent = setInterval(() => {
      if (this.isSessionExpired()) {
        clearInterval(sessionManagerAgent);
        window.alert("Your Session has expired, please login.")
        this.logoutService.logout();
      }
    }, testInterval);
  }

  private isSessionExpired() {
    return this.serverSessionInactivityTimeoutInSeconds &&
        this._lastContactWithServer + this.convertToMilliseconds(this.clientSessionInactivityTimeoutInSeconds) < Date.now();
  }

  private convertToMilliseconds(timeInSeconds: number) {
    return timeInSeconds * 1000;
  }
}