import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AuthConst } from '../model/constants/auth.constants';
import * as _ from 'lodash-es';
import { ProductLineConstants } from '../model/constants/product-line.constants';
import { environment } from '../../environments/environment';
import {ItemResponse} from '../model/item-response';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigService {
  configData: any;
  agencyCode: any;
  userName: any;
  firstName: any;
  lastName: any;
  sessionId: any;
  userPinUpdated: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  agencyName: any;
  selectedPermissions = [];
  authorities = this.permissions.authorities;
  private baseUrl = environment.baseUrl;
  userData: Object;
  environmentalLinks: any;
  userPin: any;

  constructor(private http: HttpClient, private permissions: AuthConst) {}

  getConfig() {
    console.log(window.location)
    const promise = new Promise((resolve, reject) => {
      this.getEnvironmentalLinks().subscribe(res => {
          this.environmentalLinks = res.data;
        }
      );
      this.getUserDetails().then(() => resolve());
    });
    return promise;
  }

  getUserDetails() {
    const apiUrl = `${this.baseUrl}/api/security/getUserDetails`;
    return this.http
      .post(apiUrl, {})
      .toPromise()
      .then((res) => {
        // this.agencyCode = res['data'].agencyId;
        this.userName = res['username'].replace('*', '');
        this.sessionId = res['sessionId'];
        this.firstName = res['firstName'];
        this.lastName = res['lastName'];
        this.userData = res;
        this.selectedPermissions = [];
        res['authorities'].forEach((eachObj) => {
          this.selectedPermissions.push(eachObj);
        });
        this.setUserPermissions(this.selectedPermissions);
        this.setUserPin(res['hasPin']);
      });
  }

  private getEnvironmentalLinks() {
    const url = this.baseUrl + "/api/util/environmentUrls";
    return this.http.post<ItemResponse>(url, {});
  }

  getPersonalLinesBaseURL() {
    return this.environmentalLinks ?
        this.environmentalLinks.personalLinesBaseURL :
        '';
  }

  getCommercialLinesBaseURL() {
    return this.environmentalLinks ?
        this.environmentalLinks.commercialLinesBaseURL :
        '';
  }

  getForeSightURL() {
    return this.environmentalLinks ?
        this.environmentalLinks.foresight :
        '';
  }

  getPortalLink() {
    return this.environmentalLinks ?
        this.environmentalLinks.portal :
        '';
  }

  getTaskNotesUiLink() {
    return this.environmentalLinks ?
        this.environmentalLinks.taskNotesUi :
        '';
  }

  getHomeUrl() {
    return this.environmentalLinks.citadelUi;
  }

  setUserPin(pin) {
    this.userPin = pin;
    this.userPinUpdated.next(true);
  }

  getUserPin() {
    return this.userPin;
  }

  setUserPermissions(userData) {
    const authorities = Object.assign({}, this.permissions.authorities);
    userData.forEach((eachObj) => {
      _.forEach(authorities, function (value, key) {
        if (eachObj === key) {
          authorities[key] = true;
        }
      });
    });
    this.authorities = authorities;
  }

  getAuthorities() {
    return this.authorities;
  }

  getUserData() {
    return this.userData;
  }

  getUserName() {
    return this.userName;
  }

  getSessionId() {
    return this.sessionId;
  }

  getDefaultProductLine() {
    const productLine = sessionStorage.getItem('productLine');
    return productLine
      ? productLine
      : ProductLineConstants.DEFAULT_PRODUCT_LINE;
  }
}
