import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { SpinnerService } from '../spinner/spinner.service';
import { DataStorageService } from '../../shared-services/data-storage.service';
import { finalize, catchError, timeout, map } from 'rxjs/operators';
import {throwError, Observable, OperatorFunction} from 'rxjs';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { isItemResponse, ItemResponse } from '../../model/item-response';
import { ApiConfigService } from 'src/app/shared-services/api-config.service';
import { CitadelServiceSessionManagerService } from '../session-manager/citadel-service-session-manager.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  count = 0;
  defaultspinnerInterval = 0.4;
  defaultTimeout = 600;

  constructor(
    public errorHandlerService: ErrorHandlerService,
    private spinner: SpinnerService,
    private dataService: DataStorageService,
    private router: Router,
    private env: ApiConfigService,
    private sessionManagerService: CitadelServiceSessionManagerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('citadel-service')) {
      this.refreshCitadelServiceClientInactivityTimeout();
    }

    const timeoutValue =
      Number(request.headers.get('timeout')) || this.defaultTimeout;
    const spinnerInterval =
      Number(request.headers.get('spinnerInterval')) ||
      this.defaultspinnerInterval;
    const token: string = localStorage.getItem('token');
    setTimeout(() => {
      this.count++;
      this.dataService.setHttpCount(this.count);
      if (this.count >= 1) {
        this.spinner.showLoader(true);
      }
    }, spinnerInterval * 1000);

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json'),
      });
    }
    request = request.clone({
      withCredentials: true,
    });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('HttpResponse', event);
          if (undefined !== event.body && isItemResponse(event.body)) {
            const response: ItemResponse = event.body;
            this.errorHandlerService.errorBar(response.errors);
            this.errorHandlerService.fieldErrorBar(response.fieldErrors);
            this.errorHandlerService.messageBar(response.messages);
          }
        }
        return event;
      }),
      this.noResponseFromServerTimeoutInSeconds(timeoutValue),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason:
            error && error.error && error.error.reason
              ? error.error.reason
              : error.message
              ? error.message
              : 'Error in your request',
          status: error.status,
        };
        if ((error.status === 200 || error.status === 302) && error.url) {
          this.goHome();
        }
        if (error.status === 401) {
          this.router.navigate(['/401']);
        }
        this.errorHandlerService.errorBar(data);
        return throwError(error);
      }),
      finalize(() => {
        this.count--;
        this.dataService.setHttpCount(this.count);
        if (this.count === 0) {
          this.spinner.showLoader(false);
        }
      })
    );
  }

  private refreshCitadelServiceClientInactivityTimeout() {
    this.sessionManagerService.lastContactWithServer = Date.now();
  }

  private noResponseFromServerTimeoutInSeconds(timeoutValue: number): OperatorFunction<any, any> {
    return timeout(timeoutValue * 1000);
  }

  private goHome() {
    let urlHome = this.env.getHomeUrl();
    if (urlHome) {
      window.location.href = urlHome;
    }
  }
}
