import { Injectable } from "@angular/core";
@Injectable()
export class AuthConst {
    public authorities = {
        WebCMS_AccessHPExstreamFormLetters: false,
        WebCMS_AddLegalClaimFile: false,
        WebCMS_AssignAdjusterToClaim: false,
        WebCMS_AssignIndependentAdjusterToClaim: false,
        WebCMS_ChangeClaimStatus: false,
        WebCMS_EditAdminAutomatedReservesMaint: false,
        WebCMS_EditAdminCATEventMaint: false,
        WebCMS_EditAdminCauseOfLossMaint: false,
        WebCMS_EditAdminClaimsNotesSubjectAndTemplateMaint: false,
        WebCMS_EditAdminClaimsNotesTypesMaint: false,
        WebCMS_EditAdminClaimsNotesWorkflowSecurityMaint: false,
        WebCMS_EditAdminISOLossTypeMaint: false,
        WebCMS_EditAdminMasterAddressBookMaint: false,
        WebCMS_EditAdminTasksGroupsMaint: false,
        WebCMS_EditAdminTasksMaint: false,
        WebCMS_EditAdminTasksWorkflowsMaint: false,
        WebCMS_EditAobDemand: false,
        WebCMS_EditCauseOfLossField: false,
        WebCMS_EditChangeCATEventOnClaimField: false,
        WebCMS_EditClaimantsMedicalIDNumbers: false,
        WebCMS_EditClaimantsPageAllSections: false,
        WebCMS_EditClaimInfoPageGeneralFields: false,
        WebCMS_EditClaimsManualChecks: false,
        WebCMS_EditCMSAdminAuthorityLevels: false,
        WebCMS_EditCMSAdminManageClaimsUserProfiles: false,
        WebCMS_EditCoveragesAndReserves: false,
        WebCMS_EditDateOfLossField: false,
        WebCMS_EditDCAMPageAllSections: false,
        WebCMS_EditLitigationPage: false,
        WebCMS_EditLocalAddressBookEntries: false,
        WebCMS_EditRecovery: false,
        WebCMS_EditStandardClaimsCheck: false,
        WebCMS_EditUtilityBatchIAReassign: false,
        WebCMS_EditUtilityClaimsCheckStatusMaint: false,
        WebCMS_EditUtilityForceFNOLGenAndImageRightClaimSetup: false,
        WebCMS_EditUtilitySuppressAcknowledgementLetter: false,
        WebCMS_EditUtilityVoidBulkClaimsCheckMaint: false,
        WebCMS_EnterFirstNoticeOfLoss: false,
        WebCMS_MailClaimsCheckOvernightPriority: false,
        WebCMS_PostClaimToSymbility: false,
        WebCMS_ProcessClaimsStandardCheckPrinting: false,
        WebCMS_SendPURRToUnderwriting: false,
        WebCMS_UnlockClaimRecord: false,
        WebCMS_ViewClaimantsMedicalIDNumbers: false,
        WebCMS_ViewCMSAdminAuthorityLevels: false,
        WebCMS_ViewFinancialsInfo: false,
        WebCMS_VoidClaimsManualChecks: false,
        WebCMS_VoidClaimsStandardChecks: false
    };
}
