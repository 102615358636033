import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'thig-unsaved-changes-prompt',
  templateUrl: './last-chance-prompt.component.html',
  styleUrls: ['./last-chance-prompt.component.scss'],
  animations: [
    trigger('grow', [
      transition(':enter', [
        animate('400ms ease-in-out',
          keyframes([
            style({transform: 'scale(0)'}),
            style({transform: 'scale(1.1)'}),
            style({transform: 'scale(1)'})
          ])
        )
      ]),
      transition(':leave', [
        animate('400ms ease-in-out',
          keyframes([
            style({transform: 'scale(1)'}),
            style({transform: 'scale(1.1)'}),
            style({transform: 'scale(0)'})
          ])
        )
      ])
    ])
  ]
})
export class LastChancePromptComponent implements OnInit {

  private readonly toolTipPosition: any;
  readonly dataType: DataType = 'formData';
  readonly customMessage: string;
  readonly closeOnly: boolean;
  readonly centerWarningPromptMessage: boolean;

  constructor(
    private dialogRef: MatDialogRef<LastChancePromptComponent>,
    @Inject(MAT_DIALOG_DATA) data: { toolTipPosition: any, dataType: DataType, customMessage: string, closeOnly: boolean, centerWarningPromptMessage: boolean }
  ) {
    this.toolTipPosition = data.toolTipPosition;
    this.dataType = data.dataType;
    this.customMessage = data.customMessage;
    this.closeOnly = data.closeOnly;
    this.centerWarningPromptMessage = data.centerWarningPromptMessage;
  }

  ngOnInit(): void {
    if (!this.centerWarningPromptMessage) {
      this.dialogRef.updatePosition(this.toolTipPosition);
    }
  }

  onNo() {
    this.dialogRef.close(false);
  }

  onYes() {
    this.dialogRef.close(true);
  }
}

export type DataType =
  'formData' |
  'tableData';
