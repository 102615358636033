import { Injectable } from '@angular/core';
import { filter, isEmpty } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { Alert } from './alert.constant';
import {TaskNoteMessageAlertService} from '@nextgen/task-notes-library';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService implements TaskNoteMessageAlertService {

  public alert: BehaviorSubject<Alert> = new BehaviorSubject<Alert>( null);
  constructor(private errorHandlerService: ErrorHandlerService) { }

  showAlert(obj: Alert) {
    this.alert.next(obj);
  }

  clearAlert(){
    this.alert.next(null);
  }

  showErrorMessage(errorMessages: string[]) {
    if (isEmpty(errorMessages)) {
      errorMessages = ['We could not process the request, please contact the System Administrator'];
    }
    const details = errorMessages.length > 1 ? filter(errorMessages).join(',') : errorMessages[0];
    this.errorHandlerService.errorBar(details);
  }

}
