import { ErrorHandler, Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /ChunkLoadError/g;
    if (error) {
      console.error(error);
    }
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
