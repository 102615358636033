import {CurrencyPipe, DecimalPipe} from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash-es';
import { MaskedInputType } from '../model/commom-model';

@Pipe({
  name: 'maskInputNumber'
})
export class MaskInputDecimalPipe implements PipeTransform {
  private readonly DECIMAL_SEPERATOR = '.';
  private fractionSize: number;
  constructor(
    private currencyPipe: CurrencyPipe,
  ) { }

  //TODO unable to use this pipe currently because the existing implementation is stateful and manipulates view bound properties.
  //We could separate masked-input.component into multiple components instead of using the MaskedInputType selector. That could help with readability
  transform(value: string, maskInputType: MaskedInputType, allowDecimal: boolean = false, fractionSize: number = 2): any {

    // if (maskInputType !== 'number') {
    //   return;
    // }
    return value;
  }


  // // the digit info for the decimal
  // get decimalDigitInfo(){
  //   return '1.0-' + this.fractionSize;
  // }
  //
  // // the digit info for the non-decimal number
  // get numberDigitInfo(){
  //   return '1.0-0';
  // }
  //
  // /**
  //  * The mask input pipe for the number, amount
  //  * @param value : The string value to manipulate
  //  * @param maskInputType : the type of the masked input type
  //  * @param decimalInfo : digitInfo is same as the decimal pipe of angular digitInfo.
  //  */
  // transform(value: string, maskInputType: MaskedInputType, allowDecimal: boolean = false, fractionSize: number = 2): any {
  //   console.log("transform does indeed fire");
  //   if (!value || value == null || !maskInputType) {
  //     return;
  //   }
  //   // covert number to string.
  //   value = isString(value) ? value : '' + value;
  //   this.fractionSize = fractionSize;
  //   if (value && maskInputType === 'amount') {
  //     return this._formatAmount(value, allowDecimal);
  //   } else if (value && maskInputType === 'number') {
  //     return this._formatNumber(value);
  //   }
  //   return '';
  // }
  //
  // private _formatAmount(value: string, allowDecimal?: boolean) {
  //   if (allowDecimal === true ) {
  //     value = value.replace(/[^-0-9.]/g, '');
  //     if (value.endsWith('.')){
  //       return this._formatDotEndedValue(value);
  //     }
  //     return this.decimal.transform(value, this.decimalDigitInfo);
  //   } else {
  //     return this._formatNumber(value);
  //   }
  // }
  //
  // private _formatNumber(value: string) {
  //   // value = value.replace(/^-?(\d?)+$/g, '');
  //   // return this.decimal.transform(value, this.numberDigitInfo);
  //   // this is work in progress
  //   value =  value.replace(/[^0-9-]/g, '')
  //       .replace(/(?<=[0-9-])-/g, '');
  //   return Number.isSafeInteger(value) ? this.decimal.transform(value, this.numberDigitInfo) : value;
  // }
  //
  // /**
  //  * Dot ended value, we have to handle scenarious like 22322. & 43243.3243.
  //  * 1. For scenario 22322.
  //  *  We will take out integer and format the value and append the dot after that.
  //  * 2. For scenario 43243.3243.
  //  *  Remove the last dot and format the remaining value.
  //  * @param value : value to format
  //  */
  // private _formatDotEndedValue(value): string{
  //   const firstIndex = value.indexOf('.');
  //   if (firstIndex > -1 && value.lastIndexOf(this.DECIMAL_SEPERATOR) === firstIndex && value.endsWith('.') ){
  //       // get out of the fraction . and format the amount.
  //       return this._formatNumber(value.substring(0, value.length - 1)) + '.';
  //     } else if (firstIndex > -1 && value.lastIndexOf(this.DECIMAL_SEPERATOR) !== firstIndex && value.endsWith('.')) {
  //       // now remove the .
  //       return this._formatNumber(value.substr(0, value.length - 1));
  //     }
  // }

}
