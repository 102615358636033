import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { WarningDialogService } from '../warning-dialog/warning-dialog.service';
import { map, take } from 'rxjs/operators';
import { UpdatedFieldsService } from '../../fields/helpers/updated-fields.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private warningDialogService: WarningDialogService,
              private updatedFieldsService: UpdatedFieldsService) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    // console.log('canDeactivate', component.canDeactivate());
    return component.canDeactivate() ?
        true :
        // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        this.showWarningDialog();
  }

  showWarningDialog(): Observable<boolean> {
    const options = {
      title: `There are unsaved changes on this form.`,
      message: `Do you wish to proceed and lose your unsaved changes?`
    };
    this.warningDialogService.open(options);
    return this.warningDialogService.confirmed()
        .pipe(
            take(1),
            map(confirmed => {
              if (confirmed === true) {
                this.updatedFieldsService.resetUpdateFields();
              }
              return confirmed === true;
            })
        );
  }
}
