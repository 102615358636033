import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { SharedModule } from './shared-modules/shared.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './common/http-interceptor/http-interceptor';
import { GlobalErrorHandler } from './common/global-error-handler';
import { DynamicScriptLoaderService } from './shared-services/dynamic-script-loader.service';
import { ApiConfigService } from './shared-services/api-config.service';
import { AuthConst } from './model/constants/auth.constants';
import { ProductLineConstants } from './model/constants/product-line.constants';
import { UnauthorizedErrorComponent } from './unauthorized-error/unauthorized-error.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { AppRoutingModule } from './app.routing.module';
import { OptionItemStoreService } from './shared-services/option-item/option-item-store.service';
import { OptionItemHttpService } from './shared-services/option-item/option-item-http.service';
import { DynamicPipe } from './fields/pipes/dynamic.pipe';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { WarningDialogComponent } from './common/warning-dialog/warning-dialog.component';
import { WarningDialogService } from './common/warning-dialog/warning-dialog.service';
import { PendingChangesGuard } from './common/guards/pending-changes.guard';
import { AdjusterManagementSaveGuard } from './adjuster-management/adjuster-management-save-guard.service';
import { APPLICATION_CONFIG, MESSAGE_ALERT_SERVICE } from '@nextgen/task-notes-library';

import { DcamTabsConst } from './claims/dcam/dcam.tabs.constants';
import { UniversalTableDataConverterService } from './common/universal-table/universal-table-data-converter.service';
import { AlertService } from './common/alert/alert.service';
import { OverrideComponent } from './admin/override/override.component';
import {ThigAuthorityOverrideLibraryModule} from '@nextgen/thig-authority-override-library';
import { TasksService } from './task-manager/tasks.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    UnauthorizedErrorComponent,
    WarningDialogComponent,
    OverrideComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ThigAuthorityOverrideLibraryModule
  ],
  providers: [
    ApiConfigService,
    AuthConst,
    DcamTabsConst,
    DynamicPipe,
    DecimalPipe,
    CurrencyPipe,
    DynamicScriptLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: MESSAGE_ALERT_SERVICE,
      useExisting: AlertService,
    },
    {
      provide: APPLICATION_CONFIG,
      useExisting: TasksService
    },
    OptionItemStoreService,
    OptionItemHttpService,
    PendingChangesGuard,
    ProductLineConstants,
    AdjusterManagementSaveGuard,
    DynamicPipe,
    DecimalPipe,
    WarningDialogService,
    UniversalTableDataConverterService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

