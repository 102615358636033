import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OptionItem } from '../../fields/model/commom-model';
import { ProductLineService } from 'src/app/main-menu/product-line.service';
import { OptionItemStoreService } from './option-item-store.service';

@Injectable()
export class OptionItemHttpService {
  private baseUrl = environment.baseUrl;
  private subscriptions = new Subscription();
  currentProductLine: any;

  constructor(
    private http: HttpClient,
    private productLineService: ProductLineService
  ) {
    this.subscriptions.add(
      this.productLineService.currentProductLine.subscribe((productLine) => {
        this.currentProductLine = productLine;
      })
    );
  }

  fetchActiveMgas(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getActiveMgasByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  // I'm not sure if this returns the same thing as US States from Global Controller.
  fetchActiveStates(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getActiveStatesByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchAdjusters(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getAdjustersByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchCompanyList(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getCompaniesByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchCountiesByStateList(state: string): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getCountiesByState`;
    const params = JSON.stringify({ state: state });
    console.log(state, params);
    return this.getOptionItems(url, params, true);
  }

  fetchCoverageTypes(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getCoverageTypesByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchCivilRemedyAdjusters(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getCrnAdjustersByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchDfsClaimAdjusters(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getDfsAdjustersByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchGlobalChoices(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/globalChoices`;
    return this.getOptionItems(url, {}, false, OptionItemHttpMethod.get);
  }

  fetchLegalPrefixesByClaimNumber(claimNumber: string) {
    const url = `${this.baseUrl}/api/common/getLegalPrefixes`;
    const params = JSON.stringify({ claimNumber: claimNumber, productLine: this.currentProductLine });
    return this.getOptionItems(url, params, true);
  }

  fetchLobs(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getLobsByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchLossCauses(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getLossCausesByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchLossSubCausesByLossCause(lossCauseId: string): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getLossSubCauses`;
    const params = JSON.stringify({ lossCauseId: lossCauseId });
    return this.getOptionItems(url, params, true);
  }

  fetchPaymentStatuses(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getPaymentStatuses`;
    return this.getOptionItems(url, {}, true);
  }

  fetchProductLines(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getProductLines`;
    return this.getOptionItems(url, {}, true);
  }

  fetchPropertyLossCauses(): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getPropertyLossCausesByProductLine`;
    return this.getOptionItems(
      url,
      { productLine: this.currentProductLine },
      true
    );
  }

  fetchStatesByMgaType(mgaType: string): Observable<OptionItem[]> {
    const url = `${this.baseUrl}/api/common/getStatesByMgaType`;
    const params = JSON.stringify({ mgaType: mgaType });
    return this.getOptionItems(url, params, true);
  }

  // This will call the server based upon the passed in url and return an
  // observable list of OptionItems
  private getOptionItems(
    url: string,
    params: any,
    alphabetize: boolean,
    httpMethod = OptionItemHttpMethod.post
  ): Observable<OptionItem[]> {
    let optionItemObservable;
    if (httpMethod === OptionItemHttpMethod.get) {
      optionItemObservable = this.http.get<OptionItem[]>(url);
    } else {
      optionItemObservable = this.http.post<OptionItem[]>(url, params);
    }
    return optionItemObservable.pipe(
      map((response) => {
        // response is an ItemResponse obj
        if (true === alphabetize) {
          return response['data'].sort((v1, v2) => {
            return this.orderOptionItems(v1, v2);
          });
        } else {
          return response['data'];
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  // This will order the OptionItems by text
  orderOptionItems(a: OptionItem, b: OptionItem) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }
}

enum OptionItemHttpMethod {
  get,
  post,
}
