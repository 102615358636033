import { Injectable } from '@angular/core';
import { ColumnDefinition } from '../../common/universal-table/universal-table.component';

export class DcamTemplate {
  constructor(
      public tabIndex: number,
      public title: string,
      public gridNode: string,
      public dialogTitle: string,
      public dialogNode: string,
      public dialogWidth: string,
      public gridColumnDefs: ColumnDefinition[]
  ) {
  }
}

@Injectable()
export class DcamTabsConst {
  public tabs = {
    DFS: new DcamTemplate(0,
        'DFS Inquiry',
        'dfsInquiries',
        'DFS Inquiry',
        'dfsInquiry',
        '900px',
        [
          new ColumnDefinition('complainant', 'Complainant'),
          new ColumnDefinition('assignedCompanyRepresentative', 'Company Rep Assigned'),
          new ColumnDefinition('lastName', 'Contact'),
          new ColumnDefinition('dueDate', 'Due Date'),
          new ColumnDefinition('dateCompleted', 'Date Completed')
        ]),
    CRN: new DcamTemplate(1,
        'Civil Remedy',
        'civilRemedies',
        'Civil Remedy',
        'civilRemedy',
        '900px',
        [
          new ColumnDefinition('complainant', 'Complainant'),
          new ColumnDefinition('assignedCompanyRepresentative', 'Company Rep Assigned'),
          new ColumnDefinition('dateDue', 'Due Date'),
          new ColumnDefinition('dateAnswered', 'Response File Date')
        ]),
    AOB: new DcamTemplate(2,
        'AOB Demand',
        'aobDemands',
        'Assignment of Benefits Demand',
        'aobDemand',
        '1200px',
        [
          new ColumnDefinition('assignee', 'Assignee'),
          new ColumnDefinition('taskOwner', 'Supervisor Task Owner'),
          new ColumnDefinition('dueDate', 'Demand Due Date'),
          new ColumnDefinition('completedDate', 'Demand Completed Date')
        ]),
    APP: new DcamTemplate(3,
        'Appraisal',
        'appraisals',
        'Appraisal',
        'appraisal',
        '900px',
        [
          new ColumnDefinition('requestingParty', 'Requesting Party'),
          new ColumnDefinition('companyAppraiser', 'Company Appraiser'),
          new ColumnDefinition('insuredsAppraiser', 'Insured\'s Appraiser'),
          new ColumnDefinition('datePaid', 'Date Paid')
        ]),
    MED: new DcamTemplate(4,
        'Mediation',
        'mediations',
        'Mediation',
        'mediation',
        '900px',
        [
            new ColumnDefinition('requestingParty', 'Requesting Party'),
            new ColumnDefinition('mediator', 'Mediator Contact'),
            new ColumnDefinition('dateScheduled', 'Date Scheduled'),
            new ColumnDefinition('dateClosed', 'Date Closed')
        ]),
  };
}
